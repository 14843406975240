import { useCallback, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authSelector, setAuthAction } from "../store/slice/auth";
import {
  loginAction,
  logoutAction,
  userInfoAction,
  userDetailsByIdAction,
} from "../store/actions";

/** ACCESS STATE UPDATE */
import { useMsal } from "@azure/msal-react";
import { loginRequestB2C, loginRequestORG, msalInstanceB2C, msalInstanceORG } from "../authConfig";
import {
  setAuthToken,
  getAuthToken,
  setExpiresOn,
  setAuthType,
  getAuthType
} from "../utils/storage";
import { acquireTokenWithFallback } from "../utils/common";

const useAuth = () => {
  const dispatch = useDispatch();
  const { data, loading, error, logoutError, logoutLoading } = useSelector(
    authSelector
  );

  const getAuth = useCallback((params) => dispatch(loginAction(params)), [
    dispatch,
  ]);
  const setAuth = useCallback((params) => dispatch(setAuthAction(params)), [
    dispatch,
  ]);
  // const getUserProfile = useCallback((params) => dispatch(userProfileAction(params)), [dispatch]);
  const logout = useCallback(() => dispatch(logoutAction()), [dispatch]);
  const getUserInfo = useCallback(
    (params) => dispatch(userInfoAction(params)),
    [dispatch]
  );
  const getUserDetailsById = useCallback(
    (params) => dispatch(userDetailsByIdAction(params)),
    [dispatch]
  );
  const { instance, accounts } = useMsal();

  useLayoutEffect(() => {
    if (!data.accessToken && getAuthToken() && !data.expiresOn && getAuthType()) {
      const activeInstance = (getAuthType() == "B2C") ? msalInstanceB2C : msalInstanceORG;
      const activeLoginRequest = (getAuthType() == "B2C") ? loginRequestB2C : loginRequestORG;
      // activeInstance
      //   .acquireTokenSilent({ ...activeLoginRequest, account: accounts[0] })
      //   .then((response) => {
      //     setAuth(response.accessToken);
      //     setExpiresOn(response.idTokenClaims.exp);
      //     setAuthToken(response.accessToken);
      //   });
      acquireTokenWithFallback(activeInstance, accounts[0], activeLoginRequest).then((response) => {
        setAuth(response.accessToken);
        setExpiresOn(response.idTokenClaims.exp);
        setAuthToken(response.accessToken);
      });
    }
  }, []);
  return [
    { data, loading, error, logoutError, logoutLoading },
    { getAuth, setAuth, getUserInfo, getUserDetailsById, logout },
  ];
};

export default useAuth;
