import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from "./components/spinner/spinner.component";
import useAuth from "./hooks/useAuth";
import "./styles/index.scss";
import NotificationsRealTime from "./components/common/NotificationsRealTime";
/** LAYOUTS */
const UserLayout = lazy(() =>
  import("./components/layout/user-layout.component")
);
const DefaultLayout = lazy(() =>
  import("./components/layout/default-layout.component")
);
const SettingLayout = lazy(() =>
  import("./components/layout/setting-layout.component")
);

/** PAGES */
const Home = lazy(() => import("./pages/Home/Home"));
const Teams = lazy(() => import("./pages/Teams/Teams"));
const Customers = lazy(() => import("./pages/Customers/Customers"));
const Products = lazy(() => import("./pages/Products/Products"));
const Help = lazy(() => import("./pages/Help/Help"));
const Ticket = lazy(() => import("./pages/Ticket/Ticket"));
const Login = lazy(() => import("./pages/Login/Login"));
const Member = lazy(() => import("./pages/Member/Member"));
const Notification = lazy(() => import("./pages/Notification/Notification"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const UserProfile = lazy(() => import("./pages/User/Profile"));
const CustomersDetails = lazy(() =>
  import("./pages/CustomersDetails/CustomersDetails")
);
const ProductsDetails = lazy(() =>
  import("./pages/ProductsDetails/ProductsDetails")
);
const SubscriptionDetails = lazy(() => import("./pages/Subscription/Details"));

/** ADMIN SETTING */
const Settings = lazy(() => import("./pages/Settings"));
const Roles = lazy(() => import("./pages/Settings/Manage/Roles"));
const Masters = lazy(() => import("./pages/Settings/Manage/Masters"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));

const App = () => {
  const [{ data: auth }] = useAuth();
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {/* HEADER ONLY LAYOUT */}
        {/* <Route path="/" element={ <DefaultLayout /> }>
          <Route path="login" element={<Login />} />
        </Route> */}
        <Route path="/" element={<Login />} />

        {/* HEADER WITH SIDE MENU LAYOUT */}
        <Route path="/" element={<UserLayout />}>
          <Route index path="home" element={<Home />} />
          {/* <Route path="ticket/:id" element={<Ticket />} /> */}
          <Route path="ticket">
            <Route path="detail/:workspaceId/:id" element={<Ticket />} />
          </Route>
          <Route path="teams">
            <Route path=":activeTab?" element={<Teams />} />
            <Route
              path=":activeTab?/userprofile/:id"
              element={<UserProfile />}
            />
          </Route>
          {auth.activeWorkSpace !== 3 && (
            <>
              <Route path="customers">
                <Route path="" element={<Customers />} />
                <Route
                  path="customersdetails/:id"
                  element={<CustomersDetails />}
                />
              </Route>
              <Route path="products">
                <Route path="" element={<Products />} />
                <Route path="productsdetails/:id" element={<ProductsDetails />} />
                <Route path="subscription" element={<SubscriptionDetails />} />
              </Route>
            
              <Route path="settings">
                <Route path="" element={<Settings />} />
                {/* ALLOW PAGE ACCESS ONLY FOR SUPER ADMIN & ADMIN */}
                <Route path="" element={<SettingLayout />}>
                  <Route
                    path="roles"
                    element={
                      auth.details?.isSuperAdmin ||
                      auth.details?.roleHierarchy == 1 ? (
                        <Roles />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="masters"
                    element={
                      auth.details?.isSuperAdmin ||
                      auth.details?.roleHierarchy == 1 ? (
                        <Masters />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                </Route>
              </Route>
            </>
          )}
          <Route path="Help" element={<Help />} />
          <Route path="member/:id" element={<Member />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notification">
            <Route path="detail" element={<Notification />} />
          </Route>
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <NotificationsRealTime/>
    </Suspense>
  );
};

export default App;
