import React, { useState, useEffect, Fragment } from "react";
import { getPushNotification } from "../../services";
import { useNavigate } from "react-router-dom";
import {
  closedRoundIcon,
  cardAssign,
  cardMoved,
  financialRelease,
  orionLogoWithoutText,
  upsellIcon,
  closeIconWhite,
} from "../../assets/images";
import { updateNotification } from "../../services";

const NotificationsRealTime = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [showNotify, setShowNotify] = useState(false);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("notification-enter");
  // console.log("notifications", notifications);

  const showNotificationPermissionModal = () => {
    if (Notification.permission === "denied") {
      const modal = document.createElement("div");
      modal.style.position = "fixed";
      modal.style.top = "50%";
      modal.style.left = "50%";
      modal.style.transform = "translate(-50%, -50%)";
      modal.style.padding = "20px";
      modal.style.background = "#fff";
      modal.style.border = "1px solid #ccc";
      modal.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.1)";

      const message = document.createElement("p");
      message.textContent =
        "Notifications are blocked. Please enable them in your browser settings.";
      modal.appendChild(message);

      const button = document.createElement("button");
      button.textContent = "OK";
      button.onclick = () => {
        modal.remove(); // Close modal on button click
      };
      modal.appendChild(button);
      document.body.appendChild(modal);
    }
  };

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        console.log(permission); // 'granted' or 'denied'
      });
    }
    showNotificationPermissionModal();
  }, [showNotify]);

  useEffect(() => {
    const fetchAndSetNotifications = async () => {
      try {
        const response = await getPushNotification();
        if (response.status) {
          setNotifications(response.data);
          setShowNotify(true);
          setCurrentNotificationIndex(0); // Start with the first notification
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchAndSetNotifications();

    const interval = setInterval(fetchAndSetNotifications, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (showNotify && notifications?.length > 0) {
      const timeoutDuration = notifications?.length > 1 ? 1000 : 5000;
      let timer = setTimeout(() => {
        // Apply the exit animation class
        setAnimationClass("notification-exit");

        // Wait for animation to complete, then move to next notification
        setTimeout(() => {
          if (currentNotificationIndex < notifications?.length - 1) {
            setCurrentNotificationIndex((prevIndex) => prevIndex + 1);
            setAnimationClass("notification-enter"); // Apply enter animation class
          } else {
            setShowNotify(false); // Hide after the last notification
          }
        }, 500); // 500ms matches the CSS animation duration
      }, timeoutDuration);

      return () => clearTimeout(timer);
    }
  }, [showNotify, currentNotificationIndex, notifications]);

  const handleRedirection = (info) => {
    if (info?.tool_id || info?.ticket_id) {
      updateNotification({
        ticket_id: info.ticket_id,
        tool_ticket_id: info.tool_ticket_id,
        update_team_id: info.update_team_id,
        type: info.type,
        notify_id: info.notify_id,
        status: 1,
        work_space_id: info?.workspace_id,
      }).then((res) => {
        if (res?.status) {
          navigate(
            "/ticket/detail/" + info?.workspace_id + "/" + info.ticket_id,
            {
              state: { toolId: info?.tool_id, boardId: info.board_id },
            }
          );
          setShowNotify(false);
        }
      });
    }
  };

  useEffect(() => {
    // Request notification permission if not yet granted
    if (Notification.permission === "default") {
      Notification.requestPermission();
    }

    // Function to show a single notification
    const createNotification = (notif) => {
      if (Notification.permission === "granted" && notif) {
        const url = `${window?.location?.origin}/ticket/detail/${notif?.workspace_id}/${notif?.ticket_id}`;
        const notification = new Notification(
          `Orion · ${notif?.time_ago}`, // Title with "·" symbol and time ago
          {
            body: `${notif?.header || ""} - ${
              notif?.notify_message || "You have a new notification!"
            }`,
            icon: orionLogoWithoutText, // URL to your icon image
            data: {
              url: url,
              status: "open",
            },
          }
        );
        // Add click event to open the URL
        notification.onclick = (event) => {
          event?.preventDefault();
          window?.open(notification.data.url, "_blank");
        };
      }
    };

    // Show notifications when the document visibility changes
    const handleVisibilityChange = () => {
      if (document?.hidden && showNotify) {
        const timeoutDuration = notifications?.length > 1 ? 1000 : 5000;
        notifications.forEach((notif, index) => {
          let timer = setTimeout(() => {
            if (currentNotificationIndex < notifications?.length - 1) {
              setCurrentNotificationIndex((prevIndex) => prevIndex + 1);
              createNotification(notif);
            } else {
              setShowNotify(false); // Hide after the last notification
            }
          }, timeoutDuration); // Show each notification 3 seconds apart
          return () => clearTimeout(timer);
        });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    // Clean up event listener on unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [showNotify, notifications, currentNotificationIndex]);

  const closePopup = () => {
    setShowNotify(false);
  };

  return (
    <Fragment>
      {showNotify && notifications?.length > 0 && (
        <div className={`pushNotifications-container ${animationClass}`}>
          {/* Show the current notification based on the index */}
          <Fragment>
            <h1 className="pushNotifications-container--header">
              <span className="">
                <img
                  className={"orion-logo-icon"}
                  src={orionLogoWithoutText}
                  alt={"Orion Logo Without Text"}
                />{" "}
                &#160; Orion &#183;
                <span className="time-update">
                  {notifications[currentNotificationIndex]?.time_ago}
                </span>
              </span>{" "}
              <img
                className={"closed-round-icon"}
                src={closeIconWhite}
                alt={"Closed Round Icon"}
                onClick={closePopup}
              />
            </h1>
            <div
              className={`notification_container ${
                notifications[currentNotificationIndex]?.status
                  ? `read`
                  : `unread`
              }`}
              id={notifications[currentNotificationIndex]?.notify_id}
              onClick={() =>
                handleRedirection(notifications[currentNotificationIndex])
              }
            >
              <div className={`notification_container_image`}>
                {/* Display the appropriate icon based on notification type */}
                {(notifications[currentNotificationIndex]?.type ===
                  "subtask_assignee" ||
                  notifications[currentNotificationIndex]?.type ===
                    "subtask_re_assigned" ||
                  notifications[currentNotificationIndex]?.type ===
                    "ticket_assignee" ||
                  notifications[currentNotificationIndex]?.type ===
                    "ticket_re_assigned" ||
                  notifications[currentNotificationIndex]?.type ===
                    "tool_assignee" ||
                  notifications[currentNotificationIndex]?.type ===
                    "re_assigned_tool" ||
                  notifications[currentNotificationIndex]?.type ===
                    "mentions") && <img src={cardAssign} alt="Card Assign" />}
                {notifications[currentNotificationIndex]?.type ===
                  "moved the ticket" && (
                  <img src={cardMoved} alt="Card Moved" />
                )}
              </div>

              <div className="notification_container_details">
                <div className="notification_container_details-top--row">
                  <h5 className="notification_container_details-header">
                    {notifications[currentNotificationIndex]?.header}
                  </h5>
                </div>
                <div
                  className="notification_container_details_html"
                  dangerouslySetInnerHTML={{
                    __html:
                      notifications[currentNotificationIndex]?.notify_message,
                  }}
                ></div>
              </div>
            </div>
          </Fragment>
        </div>
      )}
    </Fragment>
  );
};

export default NotificationsRealTime;
